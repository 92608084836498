// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RestApi } from 'common/services/rest-api.service';
import { IReduxState } from 'app/store';
import { useHistory, useLocation } from 'react-router-dom';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { tosterService } from 'common/services/tostar.service';
import { addHeaders } from 'common/services/rest-api.service';
import { Grid, TextField, Switch, FormControlLabel } from '@mui/material';
import { Text, Indicator } from '@cyberpion/cyberpion-ui';
import { Common } from 'common/services/common.service';
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
import LoadingButton from '@mui/lab/LoadingButton';
import queryString from 'query-string';
// import { Spinner } from "common/components/spinner/Spinner";

import { PDFContainer } from '@cyberpion/executive-report';
import { env } from 'env';

import './generate-executive-report.scss';

// BE
// feature/CP-1836-save-generated-reports-for-admin-actual
// #new-tesyt python manage.py runserver 0:8001
// FE
// CP-1929-render-executive-report-in-porta
const GenerateExecutiveReport = () => {
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);

  const {
    user: { company_name, email } //, is_report_available: isApproved = true },
  } = useSelector((state: IReduxState) => state.login);
  const [isLoading, setisLoading] = useState<boolean | null>(false);
  const [isSavingReportToServer, setIsSavingReportToServer] = useState<boolean>(false);
  const [isSaveEnable, setIsSaveEnable] = useState<boolean>(true);
  const [ts, setTS] = useState<number | null>(null);
  const [showLogo /*, setShowLogo*/] = React.useState(true);
  const [showBulk, setShowBulk] = React.useState(false);
  // const [reportType, setReportType] = useState("poc");
  const [isApproved, setIsApproved] = useState<boolean | null>(null);
  const [isPreview, setIsPreview] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [bulkCompanies, setBulkCompanies] = useState<string>('');

  const reportType = 'poc';
  const refReport = useRef();
  const refForm = useRef();

  useEffect(() => {
    Common.displayHelp(false);

    return () => {
      Common.displayHelp(true);
    };
  }, []);

  useEffect(() => {
    const areBulkCompanies = bulkCompanies.split('\n').filter(c => c !== '').length > 0;
    setIsSaveEnable((!showBulk && isPreview) || (showBulk && areBulkCompanies));
  }, [showBulk, bulkCompanies, isPreview]);

  const saveReportAndDownload = async () => {
    console.log('SEND PDF TO SERVER !!!!');
    let url;
    let data;

    if (showBulk) {
      const companies = new Set(bulkCompanies.split('\n').filter(c => c !== ''));
      url = `${env.REACT_APP_BASE_URL}internal/companies_data/${company_name}/report/executive/`;
      data = {
        report: `settings/generate-executive-report?company=%%COMPANY_NAME%%&reportType=${reportType}&logo=${
          showLogo ? 'show' : 'hide'
        }&panel=hide&isBulk=true&account=%%COMPANY_NAME%%`,
        companies: Array.from(companies),
        ...(!!globalFilter ? { global_filter: globalFilter } : {})
      };
    } else {
      url = `${env.REACT_APP_BASE_URL}reports/executive/`;
      data = {
        report: `settings/generate-executive-report?company=${company_name}&reportType=${reportType}&logo=${
          showLogo ? 'show' : 'hide'
        }&panel=hide${!!globalFilter ? '&globalFilter=' + globalFilter : ''}&account=%%COMPANY_NAME%%`,
        ...(!!globalFilter ? { global_filter: globalFilter } : {})
      };
    }

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: addHeaders('POST'),
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(data => data.json())
      .catch(err => {
        tosterService.onShowToster('Generated Report failed', 'danger', '');
        setIsSavingReportToServer(false);
      });

    if (response?.date || response?.companies) {
      const msg = response?.date
        ? 'Generated report finished successfully'
        : 'Generated Bulk Reports are being generated.';
      tosterService.onShowToster(msg, 'success', '');
      setIsSavingReportToServer(false);
    }
  };

  useEffect(() => {
    if (
      ts === null &&
      params &&
      params['company'] &&
      params['reportType'] &&
      params['panel'] &&
      params['panel'] === 'hide'
    ) {
      // http://localhost:3000/settings/generate-executive-report?company=sompo&reportType=poc&logo=show&panel=hide

      refForm.current.hidden = true;
      document.querySelector('body').style.width = '816px';
      setTS(new Date().getTime());
    }
    if (params['isBulk'] === 'true') {
      setShowBulk(true);
    }
    if (!!params['globalFilter']) {
      setGlobalFilter(params['globalFilter']);
    }
  }, [params, ts]);

  const handleBulkOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowBulk(event.target.checked);
  };

  const handlePreviewOnClick = () => {
    setTS(new Date().getTime());
    setIsPreview(true);
    setisLoading(true);
  };

  const handleSaveReportAndDownloadOnClick = () => {
    setIsSavingReportToServer(true);
    saveReportAndDownload();
  };

  const handleReportOnSuccess = () => {
    console.log('onSuccess Report');

    setisLoading(false);
  };

  const handleReportOnFailure = () => {
    tosterService.onShowToster('Generated Report failed', 'danger', '');
    setisLoading(false);
  };

  const handlerBulkCompaniesOnChange = (ev: any) => {
    setBulkCompanies(ev.target.value as string);
  };

  useEffect(() => {
    if (email && ['ionix.io', 'cyberpion.com'].includes(email.split('@')[1])) {
      setIsApproved(true);
    }
  }, [email]);

  // JUST FOR CHECKING IF THE USER LOGGED IN ALREADY
  useEffect(() => {
    if (!Object.keys(params).length) {
      RestApi.getData(`reports/executive/`).subscribe((response: any) => {});
    }
  }, [params]);

  if (isApproved !== null && !isApproved) {
    history.push('/');
  }

  return (
    <Grid container>
      <Grid item sx={{ width: 816 }}>
        {ts !== null && (
          <Grid container>
            <Grid item key={ts} ref={refReport}>
              <PDFContainer
                reportType={reportType}
                company={params['company'] || company_name}
                isBulk={showBulk}
                globalFilter={globalFilter}
                displayLogo={showLogo}
                onSuccess={handleReportOnSuccess}
                onFailure={handleReportOnFailure}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item sx={{ position: 'fixed', left: 870, paddingLeft: 8 }} ref={refForm}>
        {isSavingReportToServer && (
          <div className="generate-report-loading">
            <div>Generating Report</div>
            <div>
              <AppLoading size="small" />
            </div>
          </div>
        )}
        {!isSavingReportToServer && (
          <>
            <Grid container sx={{ paddingTop: 6, paddingRight: 14 }} flexDirection="column" spacing={2}>
              <Grid item>
                <FormControlLabel
                  value="start"
                  control={<Switch color="primary" checked={showBulk} onChange={handleBulkOnChange} />}
                  label="Enable Bulk report"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item>
                <TextField
                  sx={{ width: 250 }}
                  id="companies"
                  label="Multiple Companies"
                  multiline
                  rows={16}
                  placeholder="Multiple Companies separated by 'Enter' key"
                  onChange={handlerBulkCompaniesOnChange}
                  disabled={!showBulk}
                />
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item sx={{ paddingRight: 1 }}>
                    <Indicator type="legend" size="m" color="red" />
                  </Grid>
                  <Grid item>
                    <Text textSize={12}>Companies seperated by 'Enter' key.</Text>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <LoadingButton
                  size="medium"
                  onClick={handlePreviewOnClick}
                  disabled={showBulk}
                  variant="contained"
                  sx={{ width: 110 }}
                >
                  PREVIEW
                </LoadingButton>
                <LoadingButton
                  size="medium"
                  onClick={handleSaveReportAndDownloadOnClick}
                  disabled={!isSaveEnable}
                  variant="contained"
                  sx={{ marginLeft: 3.5, width: 110 }}
                >
                  SAVE
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: 1.5 }}>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item sx={{ paddingRight: 1 }}>
                    <Indicator type="legend" size="m" color="red" />
                  </Grid>
                  <Grid item>
                    <Text textSize={12}>
                      <b>PREVIEW</b> button is not available for bulk reports.
                    </Text>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item sm={12}>
        {isLoading && isApproved && (
          <Grid container>
            <Grid item>
              <AppLoading />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default GenerateExecutiveReport;
