import { FC, useState } from 'react';
import { Popover } from '@mui/material';
import { Text } from '@cyberpion/cyberpion-ui';
import { makeStyles } from '@material-ui/core/styles';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import classNames from 'classnames';
import { AppLoading } from 'common/components/appLoading/appLoading';

import './SubscribeDropdown.scss';

const useStyles = makeStyles({
  popoverRoot: {
    marginTop: '10px',
    minWidth: 240,
    padding: '6px',
    border: '1px solid #ecedff',
    '& .option-item': {
      color: '#5D6576',
      padding: '8px 10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#f6f8ff'
      },
      '&.selected': {
        backgroundColor: '#f6f8ff80 !important'
      },
      '& .cyberpion-ui-text': {
        borderBottom: 'none'
      }
    }
  }
});

interface SubscribeDropdownProps {
  selectedOption: any;
  options: any;
  isLoadingSubscribed: boolean;
  subscribedOnClick: any;
}
const SubscribeDropdown: FC<SubscribeDropdownProps> = ({
  selectedOption,
  options,
  isLoadingSubscribed,
  subscribedOnClick
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const classes = useStyles();

  const handleOnClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemOnClick = (option: any) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    subscribedOnClick(option);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classNames('SubscribeDropdown')}>
      <div className="dropdown" onClick={handleOnClick}>
        {isLoadingSubscribed && <AppLoading size="small" />}
        <div style={selectedOption?.name === 'unsubscribed' ? { paddingLeft: 8 } : {}}>{selectedOption?.label}</div>
        <div className="chevron-icon">
          <IconArrow toggleRotate={open} />
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popoverRoot }}
      >
        {!!options?.length &&
          options.map((option: any) => (
            <div
              key={option.name}
              className={classNames('option-item', { selected: option.label === selectedOption.label })}
              onClick={handleItemOnClick(option)}
              style={{ borderTop: option.label === 'None' ? '1px solid #ecedff' : 'none' }}
            >
              <Text textColor="#5D6576" textSize={13}>
                {option.label}
              </Text>
            </div>
          ))}
      </Popover>
    </div>
  );
};

export default SubscribeDropdown;
