import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@cyberpion/cyberpion-ui';
import Input from '../IntegrationWizard/Input/Input';
import ChipsList from '../IntegrationWizard/ChipsList/ChipsList';
import RadioButtons from '../IntegrationWizard/RadioButtons/RadioButtons';

interface IIntegrationCompletedContent {
  integration: any;
}

const IntegrationCompletedContent: React.FC<IIntegrationCompletedContent> = ({ integration }) => {
  const { integrationSchema } = useSelector((state: any) => state.integrations);
  const finalFields = integrationSchema.steps.final.read_only;
  const fieldsSchema = integrationSchema.fields;
  const fields = integration.fields;

  const getFieldProps = (id: string) => {
    const field = fieldsSchema[id];
    switch (field.type) {
      case 'label':
        return {
          id: id
        };
      case 'string':
      case 'secret':
      case 'select':
        return {
          id: id,
          title: field.label,
          value: fields[id] || '',
          is_clipboard: field.is_clipboard,
          readonly: true
          // is_error: id === 'status' && fields[id].toLowerCase() !== 'active'
        };
      case 'chip_list':
        return {
          id: id,
          title: field.label,
          valuesList: fields[id] || [],
          // is_clipboard: field.is_clipboard,
          readonly: true,
          description: field.description
          // is_error: id === 'status' && fields[id].toLowerCase() !== 'active'
        };
      case 'radio':
        return {
          id: id,
          selected: fields[id],
          readonly: true
        };
      default:
        return {};
    }
  };
  const getFinalStepFields = () => {
    const final = finalFields.map((id: string, index: number) => {
      if (id === 'name') {
        return null;
      }
      const conditions = fieldsSchema[id].display_condition;
      if (conditions) {
        const field = conditions.field;
        const value = conditions.value;

        if (fields[field] !== value) {
          return null;
        }
      }
      const props: any = getFieldProps(id);

      switch (fieldsSchema[id].type) {
        case 'label':
          return (
            <Text key={`${id}-${index}`} textSize={13} weight={500} style={{ marginBottom: 10 }}>
              {fieldsSchema[id].label}
            </Text>
          );
        case 'string':
        case 'secret':
        case 'select':
          return (
            <div key={`${id}-${index}`} style={{ marginBottom: 10 }}>
              <Input {...props} />
            </div>
          );

        case 'chip_list':
          return (
            <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
              <ChipsList {...props} />
            </div>
          );
        case 'radio':
          return (
            <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
              <RadioButtons {...props} />
            </div>
          );
        default:
          return <div key={`${id}-${index}`}>{`${id} Field type not supported`}</div>;
      }
    });
    const status = (
      <div>
        <Text textSize={13} weight={500} style={{ marginBottom: 6 }}>
          Status
        </Text>
        <Text
          textColor={!!integration.error ? '#f46666' : '#5D6576'}
          family="Rubik"
          textSize={13}
          style={{ flexGrow: 1, whiteSpace: 'nowrap' }}
        >
          {!integration.error ? 'Active' : integration.error}
        </Text>
      </div>
    );
    return [...final, status];
  };

  return <div className="IntegrationCompletedContent">{getFinalStepFields()}</div>;
};

export default IntegrationCompletedContent;
