import { useDispatch, useSelector } from 'react-redux';
import './GroupButtons.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import { IReduxState } from 'app/store';
import { editExistingGroup, saveNewGroup, togglePopup } from 'features/GroupsSlice';
import { showError, showErrorHtmlFormat, showSuccess } from 'features/AlertSlice';
import { getRows } from 'features/GridSlice';
import { useState } from 'react';
import { addNemSubsidiary, editSubsidiary, toggleSubsidiariesPopup } from 'features/SubsidiariesSlice';
import { Common } from 'common/services/common.service';

const GroupButtons = (props: any) => {
  const { rules, name, filters, editMode, id } = useSelector((state: IReduxState) => state.groups);
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const { subsidiaryName, subsidiariesEditMode, subsidiaryCountry, subsidiaryDomain, subsidiaryParent } = useSelector(
    (state: IReduxState) => state.subsidiaries
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const submit = async () => {
    setSubmitting(true);
    const groups = [];
    for (let group in rules) {
      const innerRules: any = {};
      Object.values(rules[group]).forEach((rule: any) => {
        if (rule.parameter && rule.values.length) {
          if (!rule.values.length && rule.operator.includes('isnull')) {
            if (rule.operator.includes('=True')) {
              innerRules[rule.parameter + rule.operator.replace('=True', '')] = 'True';
            } else if (rule.operator.includes('=False')) {
              innerRules[rule.parameter + rule.operator.replace('=False', '')] = 'False';
            }
          } else {
            const filterType = filters.filter((f: any) => f.name === rule.parameter)[0].type;
            innerRules[rule.parameter + rule.operator] = rule.values.map((val: any) => {
              if (['number', 'array_number'].includes(filterType) && !isNaN(val.value)) {
                return parseInt(val.value);
              }
              return val.value;
            });
          }
        }
      });
      groups.push(innerRules);
    }

    let response: any;
    if (props.type === 'Group') {
      response = await saveGroup(groups);
    } else {
      response = await saveSubsidiary(groups);
    }
    if (
      response.payload &&
      Array.isArray(response.payload.filter_rules) &&
      typeof response.payload.filter_rules[0] === 'string'
    ) {
      dispatch(showError(response.payload.filter_rules[0]));
    } else if (
      response.payload &&
      response.payload.isError &&
      !!response.payload.filter_rules &&
      typeof response.payload.filter_rules === 'object'
    ) {
      const errorList: any = Object.values(response.payload.filter_rules);
      const errorKeys = errorList.map((item: any) => Object.keys(item)[0]);
      const errorFields = columnPicker.filter((item: any) => errorKeys.includes(item.name));

      let errorMsg: any = [];
      errorFields.forEach((item: any) => {
        const err = errorList.find((error: any) => Object.keys(error)[0] === item.name);
        errorMsg.push(
          <div style={{ padding: 2 }}>{`${item.label}: ${!!err ? Object.values(err)[0] : 'Validation Error'}`}</div>
        );
      });
      dispatch(showErrorHtmlFormat(errorMsg));
    } else if (response.error) {
      dispatch(showError(response.error.message || 'Something went wrong'));
    } else if (response.payload.isError && !response.payload.filter_rules) {
      let copy = { ...response.payload };
      delete copy.isError;
      const errorKeys = Object.keys(copy);
      const errorFields = columnPicker.filter((item: any) => errorKeys.includes(item.name));

      let errorMsg: any = [];
      errorFields.forEach((item: any) => {
        const err = copy[item.name];
        errorMsg.push(<div style={{ padding: 2 }}>{`${item.label}: ${!!err ? err[0] : 'Validation Error'}`}</div>);
      });
      dispatch(showErrorHtmlFormat(errorMsg));
    } else {
      dispatch(showSuccess('Saved Successfully'));
    }
    dispatch(togglePopup(false));
    dispatch(toggleSubsidiariesPopup(false));
    dispatch(getRows());
    setSubmitting(false);
  };

  const saveSubsidiary = async (groups: any[]) => {
    let res;
    if (subsidiariesEditMode) {
      try {
        res = await dispatch(
          editSubsidiary({
            id: subsidiaryName,
            filter_rules: groups
          })
        );
        return res;
      } catch (err) {}
    } else {
      try {
        res = await dispatch(
          addNemSubsidiary({
            name: subsidiaryName,
            main_domain: subsidiaryDomain,
            ...(!!subsidiaryCountry ? { country: subsidiaryCountry } : {}),
            direct_parent: subsidiaryParent?.label || undefined,
            filter_rules: groups
          })
        );
        return res;
      } catch (err) {}
    }
  };

  const saveGroup = async (groups: any[]) => {
    let res;
    if (editMode) {
      try {
        res = await dispatch(
          editExistingGroup({
            id: id,
            name: name,
            filter_rules: groups
          })
        );
        return res;
      } catch (err) {}
    } else {
      try {
        res = await dispatch(
          saveNewGroup({
            name: name,
            filter_rules: groups
          })
        );
        return res;
      } catch (err) {}
    }
  };

  const isDisabled = () => {
    if (props.type === 'Group' && !name) {
      return true;
    }

    if (props.type === 'Subsidiary' && !subsidiaryName) {
      return true;
    }

    // if (props.type === 'Subsidiary' && !subsidiaryCountry) {
    //   return true;
    // }

    if (props.type === 'Subsidiary' && (!subsidiaryDomain || !Common.validateTldr(subsidiaryDomain))) {
      return true;
    }

    if (submitting) {
      return true;
    }

    if (!rules || !Object.keys(rules).length) {
      return true;
    }

    let hasValues = true;
    let group: any;
    for (group in rules) {
      for (let rule in rules[group]) {
        if (!rules[group][rule].values || !rules[group][rule].values.length) {
          hasValues = false;
        }
      }
    }

    if (!hasValues) {
      return true;
    }

    return false;
  };

  return (
    <div className="GroupButtons">
      <Button text="Cancel" size="medium" buttonStyle="secondary" onClick={props.onClose} type="button" />
      <Button disabled={isDisabled()} text="Save" size="medium" buttonStyle="main" onClick={submit} type="button" />
    </div>
  );
};

export default GroupButtons;
