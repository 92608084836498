// @ts-nocheck
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';

const CoverLegend = (): JSX.Element => {
  const [pages, setPages] = useState<any>([]);
  const { highlightsList } = useThreatsReport() as ThreatsReportContextType;

  useEffect(() => {
    let list = ['Threat exposure radar'];
    if (!!highlightsList.length) {
      list.push('List of highlights');
    }
    if (!!highlightsList.find((item: any) => item.severity !== '0')) {
      list.push('Top exposure examples');
    }
    setPages(list);
  }, [highlightsList]);

  return (
    <Grid container flexDirection="column">
      <Grid item style={{ marginBottom: 15 }}>
        <Text weight={600} textSize={14} upper>
          Chapters
        </Text>
      </Grid>

      <Grid item>
        {pages.map((item: string, index: number) => (
          <Grid container key={item} alignItems="center" style={{ marginBottom: 14 }}>
            <Grid item>
              <div style={{ height: 70, width: 4, backgroundColor: '#D1D2D3' }} />
            </Grid>
            <Grid item style={{ marginLeft: 20 }}>
              <Grid container flexDirection="column">
                <Grid item sx={{ paddingTop: '10px' }}>
                  <Text weight={600} textSize={13} upper>
                    {item}
                  </Text>
                </Grid>
                <Grid item>
                  <Text family="Rajdhani" textSize={32}>
                    {`Page ${index + 2}`}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default CoverLegend;
