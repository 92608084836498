import React, { useEffect, useState } from 'react';
import { Input } from 'common/components/Input/Input';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';
import { Text } from '@cyberpion/cyberpion-ui';
import { Grow } from '@mui/material';
import { DiscoveryScanReportContextType, useDiscoveryScanReport } from '../DiscoveryScanReportContext';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { setInvestigationRules, setInvestigationRulesOperator } from 'features/GridSlice';
import { ToggleButtons } from 'common/components/ToggleButtons/ToggleButtons';

import '../InvestigationTabs.scss';

const useStyles = makeStyles({
  checkboxRoot: {
    padding: '0px !important',
    width: 'auto !important',
    color: '#BEC0C5 !important',
    '& .MuiSvgIcon-root': {
      width: '18px !important',
      height: '18px !important'
    }
  }
});

const InvestigationRules = ({ data }: { data: any }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any>(data);
  const { setSelectedRules, selectedRules } = useDiscoveryScanReport() as DiscoveryScanReportContextType;
  const [view, setView] = useState('and');
  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();

  const onToggle = (option: string) => {
    setView(option.toLowerCase());
    setSelectedRules([]);
    dispatch(setInvestigationRulesOperator(option.toLowerCase()));
  };

  useEffect(() => {
    const copy = [...data];
    const selected = copy.filter((item: any) => selectedRules.includes(item.id.toString()));
    const unSelected = copy.filter((item: any) => !selectedRules.includes(item.id.toString()));

    setFilteredData([...selected, ...unSelected]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function cleanText() {
    setSearchText('');
  }

  function handleChange(value: string) {
    setSearchText(value);
    const filtered = data.filter((item: any) => item.name.toLowerCase().includes(value));
    if (value === '') {
      setFilteredData(data);
      cleanText();
    } else {
      setFilteredData(filtered);
    }
  }

  const handleCheckboxOnChange = (item: any) => (event: any) => {
    if (event.target.checked) {
      setSelectedRules([...selectedRules, item.id.toString()]);

      dispatch(setInvestigationRules([...selectedRules, item.id.toString()].join(',')));
    } else {
      const selected = selectedRules.filter((ruleId: string) => ruleId !== item.id.toString());

      dispatch(setInvestigationRules(selected.join(',')));
      setSelectedRules(selected);
    }
  };

  return (
    <Grow in={true} timeout={800}>
      <div className="InvestigationTabs">
        <div className="actions">
          <>
            {!!selectedRules.length ? (
              <>
                <div className="selected-rules">{selectedRules.length}</div>
                <Text textSize={13} textColor="#5E6575" style={{ marginLeft: 6 }}>
                  Selected Rules
                </Text>
              </>
            ) : (
              <Text textSize={13} textColor="#5E6575" style={{ marginLeft: 6 }}>
                No Selected Rules
              </Text>
            )}
            <div style={{ display: 'flex', flexGrow: 1 }} />
            <ToggleButtons options={['And', 'Or']} onToggle={onToggle} isToggle={view === 'or'} />
          </>
        </div>
        <div className="search-wrapper">
          <div className="search">
            <Input
              icon={'search'}
              type={'text'}
              value={searchText}
              // onKeyDown={handleKeyDown}
              onChange={handleChange}
              onCleanText={cleanText}
              placeholder={'Type a rule'}
            />
          </div>
        </div>
        <div className="rules-list">
          {!!filteredData?.length &&
            filteredData.map((item: any) => (
              <div key={item.id} className="rule-item-wrapper">
                <div className="rule-item">
                  <Checkbox
                    checked={selectedRules.includes(item.id.toString())}
                    onChange={handleCheckboxOnChange(item)}
                    disableRipple
                    classes={{ root: classes.checkboxRoot }}
                    sx={{
                      marginRight: 0.75
                    }}
                  />
                  <Text textColor="#5E6575" className="rule-name" style={{ padding: '0px 8px' }}>
                    {item.name}
                  </Text>
                  <div style={{ flexGrow: 1 }} />
                  <div className="status-count">
                    {!!item.confirmed && (
                      <Text
                        textColor="#1BA784"
                        className="count"
                        style={{
                          backgroundColor: '#E8F6F3',
                          marginBottom: 6,
                          width: 'fit-content',
                          justifySelf: 'flex-end'
                        }}
                        isText={false}
                      >
                        {item.confirmed}
                      </Text>
                    )}
                    {!!item.maybe && (
                      <Text
                        textColor="#DAAC06"
                        className="count"
                        style={{ backgroundColor: '#FDF8E7', width: 'fit-content', justifySelf: 'flex-end' }}
                        isText={false}
                      >
                        {item.maybe}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Grow>
  );
};

export default InvestigationRules;
