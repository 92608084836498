import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import Tooltip from '@mui/material/Tooltip';
import { Text } from '@cyberpion/cyberpion-ui';
import { useSelector } from 'react-redux';

import './DownloadReportRow.scss';

export function DownloadReportRow(props: any) {
  const { globalFilter } = useSelector((state: any) => state.general);

  const getLink = (e: any) => {
    e.preventDefault();
    RestApi.setData(`reports/${props.type}/${props.data.name}/link/`, {}, 'POST').subscribe((response: any) => {
      window.open(response.link);
    });
  };

  // const companyName = props.data?.report_company || '';

  const lineText = (
    <div style={{ display: 'flex', alignContent: 'center' }}>
      {!globalFilter && !!props.data?.report_company_display ? (
        <Text weight={500}>{`${props.data?.report_company_display} - `}</Text>
      ) : (
        <></>
      )}
      <div>{`${Common.getDateString(props.data.date)} (${Common.getFileSize(props.data.size)}MB)`}</div>
    </div>
  );

  return (
    <li className="DownloadReportRow" key={props.data.name}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.isLatest && <span className="latest">Latest</span>}
        <Tooltip
          title={lineText}
          placement="top-start"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#4D4C53',
                color: '#FFFFFF !important',
                padding: '3px 6px',
                marginBottom: '0px !important',
                '& .MuiTooltip-arrow': {
                  color: '#4D4C53'
                },
                '& .cyberpion-ui-text': {
                  color: '#FFFFFF !important',
                  fontWeight: 400
                },
                border: '1px solid black',
                borderRadius: 1
              }
            }
          }}
        >
          <span
            style={{
              fontSize: 12,
              width: 295,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              display: 'block'
            }}
          >
            {lineText}
          </span>
        </Tooltip>
      </div>
      <button onClick={getLink}>Download</button>
    </li>
  );
}
