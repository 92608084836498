import React, { useState } from 'react';
import { Grow } from '@mui/material';
import { Input } from 'common/components/Input/Input';
import { Text } from '@cyberpion/cyberpion-ui';

import '../InvestigationTabs.scss';
import SavedViews from './SavedViews/SavedViews';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  clearAllFilters,
  getRows,
  inputSearch,
  removeAllRows,
  setFields,
  setfiltersResetDefault,
  sort
} from 'features/GridSlice';
import { Common } from 'common/services/common.service';
import { selectView, setIsEdited } from 'features/ViewsSlice';
import { DiscoveryScanReportContextType, useDiscoveryScanReport } from '../DiscoveryScanReportContext';

const PAGE = 'Action Items';
const TAB = 'actionItemsOpen';

const InvestigationSaveViews = ({ data }: { data: any }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any>(data);
  const { setSaveCustomViewVisible } = useDiscoveryScanReport() as DiscoveryScanReportContextType;
  const { defaultViews } = useSelector((state: any) => state.views);
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
    }
  };

  const cleanText = () => {
    setSearchText('');
  };

  const handleChange = (value: string) => {
    setSearchText(value);
    const filtered = filteredData.filter((item: any) => item.rule.toLowerCase().includes(value));
    if (value === '') {
      setFilteredData(data);
      cleanText();
    } else {
      setFilteredData(filtered);
    }
  };

  const backToDefault = (ev: React.MouseEvent<HTMLElement>) => {
    dispatch(removeAllRows());
    dispatch(sort(''));
    dispatch(clearAllFilters());
    dispatch(inputSearch(''));
    dispatch(setFields([]));

    if (defaultViews[PAGE] && defaultViews[PAGE][TAB] && defaultViews[PAGE][TAB].views[0].path) {
      history.push(Common.getDecodedViewPath(defaultViews[PAGE][TAB].views[0].path));
      dispatch(selectView(defaultViews[PAGE][TAB].views[0].id));
    } else {
      // const tabs = TABS[PAGE];
      // const tab: any = Object.values(tabs).filter((tab: any) => window.location.pathname.includes(tab.link))[0];
      // if (tab && tab.link) {
      dispatch(setfiltersResetDefault(true));
      history.push(
        Common.getDecodedViewPath(
          '/pages/actionItems/open?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Cis_acknowledged%2Ctags%2Cgroups&filters=%7B"current_state__in"%3A"open%2Cin_progress"%7D'
        )
      );
      dispatch(selectView(null));
      dispatch(getRows());
    }
    // }
    dispatch(setIsEdited(false));
  };

  const handleSaveAs = (ev: any) => {
    ev.stopPropagation();
    setSaveCustomViewVisible(true);
  };

  return (
    <Grow in={true} timeout={800}>
      <div className="InvestigationTabs">
        <div className="search-wrapper">
          <div className="search">
            <Input
              icon={'search'}
              type={'text'}
              value={searchText}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onCleanText={cleanText}
              placeholder={'Type a rule'}
            />
          </div>
        </div>
        <div className="views-list">
          {/* {!!filteredData.length &&
            filteredData.map((item: any) => (
              <div key={item.id} className="rule-item-wrapper">
                <div className="rule-item">
                  <Text textColor="#5E6575" className="rule-name" style={{ padding: '0px 8px' }}>
                    {item.rule}
                  </Text>
                  <div style={{ flexGrow: 1 }} />
                </div>
              </div>
            ))} */}
          <SavedViews page={'Action Items'} tab={'actionItemsOpen'} />
          {/* <SavedViews page={'discovery-investigation'} tab={'tool'} open={true} /> */}
        </div>
        <div className="save-views-footer">
          <div onClick={backToDefault}>
            <Text textColor="#5D6576" className="restore">
              Restore to Default
            </Text>
          </div>
          <div onClick={handleSaveAs}>
            <Text textColor="#3556DD" className="save-as">
              Save View As
            </Text>
          </div>
        </div>
        {/* {saveCustomViewVisible && <SaveCustomView onClose={() => setSaveCustomViewVisible(false)} />} */}
      </div>
    </Grow>
  );
};

export default InvestigationSaveViews;
