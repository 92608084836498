import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { PopSideHeader } from '../PopSideHeader/PopSideHeader';
import { RestApi } from 'common/services/rest-api.service';
import { Text } from '@cyberpion/cyberpion-ui';
import { Button } from 'common/components/Button/Button';
import classNames from 'classnames';
import Fade from '@mui/material/Fade';
import IntegrationEmptyState from '../../IntegrationEmptyState/IntegrationEmptyState';
import Link from '@mui/material/Link';
import IntegrationCompletedItem from '../IntegrationCompletedItem/IntegrationCompletedItem';
import {
  fetchIntegrationList,
  fetchIntegrationSchema,
  setCurrentStep,
  setFieldsValue
} from 'features/IntegrationsSlice';
import DeletePopSide from '../DeletePopSide/DeletePopSide';
import { setDelay, showError, showSuccess } from 'features/AlertSlice';

import './IntegrationList.scss';

const iconMiddle = (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="13.3333" fill="#F0F2F6" />
    <path
      d="M31.9998 59.9989L24.0004 59.9995C22.939 59.9995 21.9218 59.5777 21.1719 58.8277C20.4219 58.0777 20 57.0604 20 55.9991V36.0006H31.9998L31.9998 59.9989ZM55.9995 59.9989L36.0009 59.9995V35.9999H60.0006V56.0002V55.9995C60.0006 57.0609 59.5787 58.078 58.8287 58.828C58.0788 59.578 57.0615 59.9999 56.0002 59.9999L55.9995 59.9989ZM59.9992 31.9998H20.0003V24.0004C20.0003 22.939 20.4222 21.9218 21.1722 21.1719C21.9222 20.4219 22.9394 20 24.0007 20H56.0009H56.0002C57.0616 20 58.0787 20.4219 58.8287 21.1719C59.5787 21.9218 60.0006 22.9391 60.0006 24.0004L59.9992 31.9998Z"
      fill="white"
    />
  </svg>
);

const iconTrash = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M2.625 3.75L3.30432 10.2035C3.3445 10.5852 3.66638 10.875 4.0502 10.875H7.9498C8.33362 10.875 8.6555 10.5852 8.69568 10.2035L9.375 3.75"
      stroke="#5D6576"
      stroke-linecap="round"
    />
    <path d="M4.875 5.625V8.625" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.125 5.625V8.625" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.375 2.875H10.125C10.4011 2.875 10.625 3.09886 10.625 3.375C10.625 3.65114 10.4011 3.875 10.125 3.875H1.875C1.59886 3.875 1.375 3.65114 1.375 3.375C1.375 3.09886 1.59886 2.875 1.875 2.875H3.625L3.625 1.875C3.625 1.18464 4.18464 0.625 4.875 0.625H7.125C7.81536 0.625 8.375 1.18464 8.375 1.875V2.875ZM7.375 2.875V1.875C7.375 1.73693 7.26307 1.625 7.125 1.625L4.875 1.625C4.73693 1.625 4.625 1.73693 4.625 1.875L4.625 2.875H7.375Z"
      fill="#5D6576"
    />
  </svg>
);

interface IIntegrationList {
  type: string;
  onAdd: () => void;
  onContinue: () => void;
}

export const IntegrationList: React.FC<IIntegrationList> = ({ type, onAdd, onContinue }) => {
  const [showDeletePopside, setShowDeletePopside] = useState(false);
  const [selectedDeletedID, setSelectedDeletedID] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');
  const [filteredIntegrationList, setFilteredIntegrationList] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { integrationList, integrationSchema, statusIntegrationList, statusIntegrationSchema } = useSelector(
    (state: any) => state.integrations
  );

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(fetchIntegrationList(type));
    dispatch(fetchIntegrationSchema(type));
  }, [dispatch, type]);

  useEffect(() => {
    setIsLoading(statusIntegrationList !== 'succeed' || statusIntegrationSchema !== 'succeed');
  }, [statusIntegrationList, statusIntegrationSchema]);

  useEffect(() => {
    if (statusIntegrationList === 'succeed' && statusIntegrationSchema === 'succeed') {
      if (search === '') {
        setFilteredIntegrationList(integrationList);
      } else {
        const { search_fields } = integrationSchema;
        if (!search_fields) {
          setFilteredIntegrationList(
            integrationList.filter((integration: any) => integration.name.toLowerCase().includes(search.toLowerCase()))
          );
        } else {
          setFilteredIntegrationList(
            integrationList
              // .filter((integration: any) => integration.is_final)
              .filter((integration: any) => {
                return search_fields.some((field: string) => {
                  if (field === 'name' || !integration.is_final) {
                    return integration.name.toLowerCase().includes(search.toLowerCase());
                  } else if (!integration.fields[field]) {
                    return true;
                  }
                  return Array.isArray(integration.fields[field])
                    ? integration.fields[field].some((item: string) =>
                        item.toLowerCase().includes(search.toLowerCase())
                      )
                    : integration.fields[field].toLowerCase().includes(search.toLowerCase());
                });
              })
          );
        }
      }
    }
  }, [search, integrationList, statusIntegrationList, statusIntegrationSchema, integrationSchema]);

  const handleAddIntegration = () => {
    onAdd();
  };

  const trashOnClick = (id: string) => (ev: any) => {
    ev.stopPropagation();
    setSelectedDeletedID(id);
    setShowDeletePopside(true);
  };

  const handleContinueProgress = (integration: any) => () => {
    dispatch(setCurrentStep(integration.current_step));
    dispatch(setFieldsValue({ id: integration.id, name: integration.name, ...integration.fields }));
    onContinue();
  };

  const handleOnEdit = (id: string, name: string, step: number, fields: any) => {
    dispatch(setCurrentStep(step));
    dispatch(setFieldsValue({ id: id, ...fields }));
    onContinue();
  };

  const handleOnDelete = () => {
    setIsLoading(true);
    const url = `settings/integrations/connections/connection/${selectedDeletedID}/`;
    setSelectedDeletedID(null);
    RestApi.setData(url, {}, 'DELETE').subscribe(
      (response: any) => {
        setIsLoading(false);
        dispatch(setDelay(4000));
        dispatch(showSuccess('Deleted Successfully'));
        dispatch(fetchIntegrationList(type));
      },
      error => {
        setIsLoading(false);
        dispatch(showError(error));
      }
    );
    setShowDeletePopside(false);
  };

  const handleDeleteOnCancel = () => {
    setSelectedDeletedID(null);
    setShowDeletePopside(false);
  };

  const handlePopSideOnClick = (ev: any) => {
    ev.stopPropagation();
    setSelectedDeletedID(null);
    setShowDeletePopside(false);
  };

  const handleOnSearch = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <div style={{ height: '100%' }}>
      {isLoading && <AppLoading />}
      {!isLoading && (
        <div className="IntegrationList" onClick={handlePopSideOnClick}>
          <PopSideHeader name={integrationSchema?.label} />
          <div
            className={classNames({ 'show-delete-popside': showDeletePopside })}
            style={{ display: 'flex', alignItems: 'center', paddingRight: 15 }}
          >
            <Text
              textColor="#8C909A"
              style={{ width: 200, marginTop: 5, marginRight: 20, lineHeight: 1.5, flexGrow: 1 }}
            >
              {integrationSchema?.list_description}
            </Text>
            <Button
              buttonStyle=""
              size="big"
              text={integrationSchema?.action_text}
              onClick={handleAddIntegration}
              type="button"
              className={`add-button`}
            />
          </div>
          {!integrationList.length && (
            <Fade in timeout={800}>
              <div>
                <IntegrationEmptyState text={integrationSchema?.empty_state} />
              </div>
            </Fade>
          )}
          {!!integrationList.length && (
            <Fade in timeout={800}>
              <div className={classNames({ 'show-delete-popside': showDeletePopside })}>
                <div className="list-header" style={{ paddingTop: 16, paddingBottom: 12, marginRight: 16 }}>
                  <Text textSize={13} weight={500} family="Rubik">
                    {`${integrationSchema?.completed_label} (${integrationList.length})`}
                  </Text>
                  <div style={{ flexGrow: 1 }} />
                  <div className="Search">
                    <img alt="Search" src="/assets/images/search.svg" style={{ width: 12, height: 12 }} />
                    <input id="input-search" type="text" maxLength={30} value={search} onChange={handleOnSearch} />
                  </div>
                </div>
                <div className="integrations-container">
                  {!filteredIntegrationList.length && (
                    <div className="icon-empty-search">
                      <div>{iconMiddle}</div>
                      <Text textSize={13} textColor="#8C909A" style={{ marginTop: 12 }}>
                        No information to present
                      </Text>
                    </div>
                  )}
                  {!!filteredIntegrationList.length &&
                    filteredIntegrationList.map((integration: any, index: number) => {
                      if (!integration.is_final) {
                        return (
                          <div key={index} className="integration-box in-progress">
                            <Text textSize={13} style={{ flexGrow: 1 }}>
                              {integration.name}
                            </Text>
                            <Link
                              component="button"
                              variant="body2"
                              className="continue-progress"
                              onClick={handleContinueProgress(integration)}
                            >
                              Continue Setup
                            </Link>
                            <div className="trash-icon" onClick={trashOnClick(integration.id)}>
                              {iconTrash}
                            </div>
                          </div>
                        );
                      }
                      return (
                        <IntegrationCompletedItem
                          key={index}
                          integration={integration}
                          type={type}
                          trashOnClick={trashOnClick(integration.id)}
                          onEdit={handleOnEdit}
                        />
                      );
                    })}
                </div>
              </div>
            </Fade>
          )}

          {showDeletePopside && <DeletePopSide onCancel={handleDeleteOnCancel} onDelete={handleOnDelete} />}
        </div>
      )}
    </div>
  );
};
