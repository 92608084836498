import React, { useState, useEffect } from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from 'common/components/Search/Search';
import { RestApi } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
import Tooltip from '@mui/material/Tooltip';
import { addDownloadFileData, setNewDownloads } from 'features/DownloadsSlice';
import { Image } from 'common/components/image/Image';

import './FqdnsInfoPanel.scss';

const FqdnsInfoPanel = () => {
  const { infoPanelAsset } = useSelector((state: any) => state.infoPanel);
  const [infoPanelData, setInfoPanelData] = useState<any>([]);
  const [searchedData, setSearchedData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!!infoPanelAsset) {
      if (Common.validateIp(infoPanelAsset)) {
        const url = `discovery/org-assets/?ips__overlap=${infoPanelAsset}&ordering=asset&fields=asset&type__in=0,1,2,5&is_fqdn_not_null=True&limit=2000`;
        RestApi.getData(url).subscribe((response: any) => {
          const data = response.results.map((item: any) => item.asset);
          setInfoPanelData(data);
          setSearchedData(data);
          setTitle(`${response.count} FQDNs`);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }
  }, [infoPanelAsset]);

  const onSearch = (pattern: string) => {
    if (pattern === '') {
      setSearchedData(infoPanelData);
    } else {
      const filteredData = infoPanelData.filter((item: any) => item.includes(pattern));
      setSearchedData(filteredData);
    }
  };

  const handleCsvDownloadOnClick = async () => {
    const exportCsvPath = Common.AddMsspAccountIfNeeded(
      `discovery/org-assets/?ips__overlap=${infoPanelAsset}&ordering=asset&is_fqdn_not_null=True&fields=asset&format=csv`
    );
    const response = await RestApi.simpleAsyncGet(exportCsvPath);
    dispatch(addDownloadFileData(response));
    dispatch(setNewDownloads(response?.id));
  };

  return (
    <div className="fqdns-info-panel" style={isLoading ? { width: 300 } : {}}>
      {isLoading && <AppLoading />}
      {!isLoading && (
        <>
          <div className="header">
            <Text textSize={13} weight={600}>
              {title}
            </Text>
          </div>
          <div className="info-panel-actions">
            <Search onChange={onSearch} />
            <Tooltip
              title="Download CSV"
              placement="bottom-end"
              arrow={true}
              classes={{ tooltip: 'download-csv-tooltip' }}
            >
              <div className="download-csv-link" onClick={handleCsvDownloadOnClick}>
                <Image className="gridUtilities-csv" alt="CSV" icon={'csv'} type="svg" />
              </div>
            </Tooltip>
          </div>
          <div className="scrollbar-common">
            <div className="info-panel-wrapper">
              {!!searchedData &&
                searchedData.map((item: any) => {
                  return (
                    <div className="info-panel-fqdns-item">
                      <LinkNewTab
                        to={`/pages/singleAsset/internalAssets/${item}/actionItems`}
                        text={<Text textSize={13}>{item}</Text>}
                        className="fqdn-link"
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FqdnsInfoPanel;
