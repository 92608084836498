import React, { useState } from 'react';
import { HoveredPopover, Text } from '@cyberpion/cyberpion-ui';

import './DiscoveryScanApproved.scss';
import classNames from 'classnames';

const DiscoveryScanApproved = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handlePopoverOpen = (event: any) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      onClick={handlePopoverOpen}
      className={classNames('DiscoveryScanApproved', { canHover: !!props.value?.rules?.length })}
      style={{ backgroundColor: props.field === 'confident_rules' ? '#E7F6F3' : '#FCF7E7' }}
    >
      <div>
        <Text textColor={props.field === 'confident_rules' ? `#1AA883` : '#E7B70E'}>
          {props.value?.rules?.length || 0}
        </Text>
      </div>
      {!!props.value?.rules?.length && (
        <HoveredPopover
          data={props?.value?.rules || []}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          bulletColor={props.field === 'confident_rules' ? '#1AA883' : '#E7B70E'}
          title={props.field === 'confident_rules' ? 'Confirmed' : 'Maybe'}
          onClose={handlePopoverClose}
          className="discovery-investigation-asset-rules-popover"
        />
      )}
    </div>
  );
};

export default DiscoveryScanApproved;
