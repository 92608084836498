// @ts-nocheck
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import USER_ROLES from 'common/constants/roles';
import { addHeaders } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';
import { env } from 'env';

export const setUserGroups = createAsyncThunk('groups/setUserGroups', async data => {
  const path = `${env.REACT_APP_BASE_URL}settings/users/user-management/${data.row.id}/`;
  const res = await fetch(path, {
    method: 'PATCH',
    mode: 'cors',
    headers: addHeaders('PATCH'),
    credentials: 'include',
    body: JSON.stringify({
      group_restrictions: data.items,
      role: data.items.length ? 35 : 30
    })
  }).then(data => data.json());
  data.apiRef.updateRows([{ id: data.row.id, role: USER_ROLES[res.role], group_restrictions: res.group_restrictions }]);
  return res;
});

export const saveNewGroup = createAsyncThunk('groups/saveNewGroup', async (data: any) => {
  const path = `${env.REACT_APP_BASE_URL}settings/groups/`;
  const res = await fetch(path, {
    method: 'POST',
    mode: 'cors',
    headers: addHeaders('POST'),
    credentials: 'include',
    body: JSON.stringify(data)
  }).then(async data => {
    let response = await data.json();
    if (!data.ok) {
      response = { ...response, isError: true };
    }
    return response;
  });
  // data.apiRef.updateRows([{ id: data.row.id, role: USER_ROLES[res.role], group_restrictions: res.group_restrictions }]);
  return res;
});

export const editExistingGroup = createAsyncThunk('groups/editExistingGroup', async (data: any) => {
  const path = `${env.REACT_APP_BASE_URL}settings/groups/${data.id}/`;
  const res = await fetch(path, {
    method: 'PUT',
    mode: 'cors',
    headers: addHeaders('PUT'),
    credentials: 'include',
    body: JSON.stringify({
      name: data.name,
      filter_rules: data.filter_rules
    })
  }).then(async data => {
    let response = await data.json();
    if (!data.ok) {
      response = { ...response, isError: true };
    }
    return response;
  });
  // data.apiRef.updateRows([{ id: data.row.id, role: USER_ROLES[res.role], group_restrictions: res.group_restrictions }]);
  return res;
});

export const getMetaGroups = createAsyncThunk('groups/getMetaGroups', async data => {
  const path = `${env.REACT_APP_BASE_URL}settings/meta-groups/?fields=name,id`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const getGroupsFilters = createAsyncThunk('groups/getGroupsFilters', async data => {
  const path = `${env.REACT_APP_BASE_URL}settings/groups/columns/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const getGroups = createAsyncThunk('groups/getGroups', async (data?: any = {}, { rejectWithValue }) => {
  const path = `${env.REACT_APP_BASE_URL}settings/groups/?fields=name,id&limit=1000${
    data?.showGroupsWithoutSubsidiaries ? '&type__in=1,4 ' : ''
  }`;
  try {
    const response = await fetch(path, {
      method: 'GET',
      mode: 'cors',
      headers: addHeaders('GET'),
      credentials: 'include'
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchMetaGroupByName = createAsyncThunk(
  'groups/fetchMetaGroupByName',
  async (metaGroupName: string, { rejectWithValue }) => {
    const path = `${env.REACT_APP_BASE_URL}settings/meta-groups/${metaGroupName}/`;
    try {
      const response = await fetch(path, {
        method: 'GET',
        mode: 'cors',
        headers: addHeaders('GET'),
        credentials: 'include'
      });

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
    bulkVisible: false,
    popupVisible: false,
    editMode: false,
    subsidiaryMode: false,
    label: '',
    identifier: '',
    domains: [],
    ips: [],
    mobileApps: [],
    whois: [],
    services: [],
    search: '',
    groups: [],
    metaGroups: [],
    selectedMetaGroup: {},
    metaGroupSelectedId: -1,
    metaGroupPopupVisible: false,
    metaGroupEditMode: false,
    statusGroups: 'idle',
    statusMetaGroups: 'idle',
    statusMetaGroupByName: 'idle',
    rules: null,
    filters: [],
    scroll: 0,
    name: '',
    id: null
  },
  reducers: {
    addRulesGroup(state) {
      const groupId = Common.generateRandomId();
      const ruleId = Common.generateRandomId();
      state.rules[groupId] = {
        [ruleId]: {
          operator: '',
          parameter: '',
          values: []
        }
      };
      state.scroll = state.scroll + 1;
    },
    editRule(state, action) {
      state.rules[action.payload.groupId][action.payload.ruleId][action.payload.key] = action.payload.val;
    },
    addInnerRule(state, action) {
      const ruleId = Common.generateRandomId();
      state.rules[action.payload][ruleId] = {
        operator: '',
        parameter: '',
        values: []
      };
      if (Object.keys(state.rules).indexOf(action.payload) === Object.keys(state.rules).length - 1) {
        state.scroll = state.scroll + 1;
      }
    },
    setGroupName(state, action) {
      state.name = action.payload;
    },
    removeRule(state, action) {
      delete state.rules[action.payload.groupId][action.payload.ruleId];
      if (!Object.keys(state.rules[action.payload.groupId]).length) {
        delete state.rules[action.payload.groupId];
      }
    },
    setRules(state, action) {
      state.rules = action.payload;
    },
    toggleMetaGroupPopup(state, action) {
      state.metaGroupPopupVisible = action.payload;
    },
    setMetaGroupEditMode(state, action) {
      state.metaGroupEditMode = action.payload;
    },
    setIdentifier(state, action) {
      state.identifier = action.payload;
    },
    toggleBulkVisibily(state, action) {
      state.bulkVisible = action.payload;
    },
    togglePopup(state, action) {
      state.popupVisible = action.payload;
      if (!action.payload) {
        state.rules = null;
        state.name = '';
        state.id = null;
        state.editMode = false;
      }
    },
    setLabel(state, action) {
      state.label = action.payload;
    },
    setEditMode(state, action) {
      state.editMode = action.payload;
    },
    resetGroupsState(state) {
      state.domains = [];
      state.ips = [];
      state.whois = [];
      state.mobileApps = [];
      state.services = [];
      state.bulkVisible = false;
      state.editMode = false;
      state.subsidiaryMode = false;
      state.label = '';
      state.statusGroups = 'idle';
      state.rules = null;
      state.name = '';
    },
    resetMetaGroupsState(state) {
      state.selectedMetaGroup = {};
      state.metaGroupSelectedId = -1;
      state.metaGroupEditMode = false;
      state.metaGroupPopupVisible = false;
      state.statusMetaGroups = 'idle';
      state.statusMetaGroupByName = 'idle';
    },
    addDomain(state, action) {
      state.domains.push(action.payload);
    },
    addIp(state, action) {
      if (!state.ips.filter(ip => ip.value === action.payload.value).length) {
        state.ips.unshift(action.payload);
      }
    },
    addWhois(state, action) {
      state.whois.unshift(action.payload);
    },
    addMobileApp(state, action) {
      state.mobileApps.unshift(action.payload);
    },
    removeMobileApp(state, action) {
      state.mobileApps.forEach((app, index) => {
        if (app.value === action.payload) {
          state.mobileApps.splice(index, 1);
        }
      });
    },
    toggleService(state, action) {
      if (state.services.includes(action.payload)) {
        state.services.splice(state.services.indexOf(action.payload), 1);
      } else {
        state.services.push(action.payload);
      }
    },
    removeServicesString(state) {
      state.services = state.services.filter(s => typeof s === 'number');
    },
    removeDomain(state, action) {
      let indexToRemove = -1;
      state.domains.forEach((row, index) => {
        if (state.subsidiaryMode && index < 2) {
          return;
        }
        if (row.value === action.payload) {
          indexToRemove = index;
        }
      });
      if (indexToRemove >= 0) {
        state.domains.splice(indexToRemove, 1);
      }
    },
    removeIp(state, action) {
      let indexToRemove = -1;
      state.ips.forEach((row, index) => {
        if (row.value === action.payload) {
          indexToRemove = index;
        }
      });
      if (indexToRemove >= 0) {
        state.ips.splice(indexToRemove, 1);
      }
    },
    removeWhois(state, action) {
      let indexToRemove = -1;
      state.whois.forEach((row, index) => {
        if (row.whois + row.type === action.payload) {
          indexToRemove = index;
        }
      });
      if (indexToRemove >= 0) {
        state.whois.splice(indexToRemove, 1);
      }
    },
    setSubsidiaryMode(state, action) {
      state.subsidiaryMode = action.payload;
    },
    inputSearch(state, action) {
      state.search = action.payload;
    },
    setMetaGroupSelectedId(state, action) {
      state.metaGroupSelectedId = action.payload;
    },
    setGroupId(state, action) {
      state.id = action.payload;
    }
  },
  extraReducers: {
    [getGroups.fulfilled]: (state, { payload }) => {
      state.groups = payload.results;
      state.statusGroups = 'succeed';
    },
    [getGroups.rejected]: (state, action) => {
      state.error = action.error;
      state.statusGroups = 'failed';
    },
    [getGroups.pending]: state => {
      state.statusGroups = 'loading';
    },
    [getMetaGroups.fulfilled]: (state, { payload }) => {
      state.metaGroups = payload.results;
      state.statusMetaGroups = 'succeed';
    },
    [getMetaGroups.rejected]: (state, action) => {
      state.error = action.error;
      state.statusMetaGroups = 'failed';
    },
    [getMetaGroups.pending]: state => {
      state.statusMetaGroups = 'loading';
    },
    [fetchMetaGroupByName.fulfilled]: (state, action) => {
      state.selectedMetaGroup = action.payload;
      state.statusMetaGroupByName = 'succeed';
    },
    [fetchMetaGroupByName.rejected]: (state, action) => {
      state.error = action.error;
      state.statusMetaGroupByName = 'failed';
    },
    [fetchMetaGroupByName.pending]: state => {
      state.statusMetaGroupByName = 'loading';
    },
    [getGroupsFilters.fulfilled]: (state, action) => {
      state.filters = action.payload.data.splice(3, action.payload.data.length - 3);
    }
  }
});

const { actions, reducer } = groupsSlice;
export const {
  toggleBulkVisibily,
  togglePopup,
  setLabel,
  setEditMode,
  setSubsidiaryMode,
  resetGroupsState,
  addDomain,
  addIp,
  addMobileApp,
  addWhois,
  toggleService,
  removeDomain,
  removeIp,
  removeMobileApp,
  removeWhois,
  setIdentifier,
  removeServicesString,
  inputSearch,
  setMetaGroupSelectedId,
  resetMetaGroupsState,
  setMetaGroupEditMode,
  toggleMetaGroupPopup,
  addRulesGroup,
  editRule,
  removeRule,
  addInnerRule,
  setGroupName,
  setRules,
  setGroupId
} = actions;

export default reducer;
