import React, { useEffect, useState } from 'react';
import { Grid } from 'common/components/grid/Grid';
import { RestApi } from 'common/services/rest-api.service';
import { IColumnPicker, IRootColumnPicker } from 'common/interfaces/interface';
import { Paths } from 'common/constants/paths';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { OPTIONS, TABSET } from './config';
import { GridRowData, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { PopUp } from 'common/components/popUp/PopUp';
import { AcknowledgeReason } from './acknowledgeReason/AcknowledgeReason';
import { ISelectionRowsChange } from 'common/components/grid/interface';
import {
  addFields,
  removeAllRows,
  removeRowsByIndex,
  resetQueryString,
  setAdditionalFields,
  setFields,
  setInitialPath,
  setShouldOpenPopsideCommentsTab,
  setShowAddCommentPopup,
  updateRescan,
  updateRows
} from 'features/GridSlice';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
// import { ActionItemsInfo } from 'common/components/popUp/components/actionItemsInfo/ActionItemsInfo';
import { tosterService } from 'common/services/tostar.service';
import { urlToStateConverter } from 'common/services/UrlToStateConverter.service';
import TopPanel from 'common/components/TopPanel/TopPanel';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import useShowToggle from 'common/hooks/useShowToggle';
import { Grid as MUIGrid } from '@mui/material';
import { TopPanelLoadingState } from 'common/components/TopPanel/TopPanelLoadingState/TopPanelLoadingState';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import { SCAN_STATUS } from 'common/components/ScanStatus/ScanStatus';
import { PopSide } from 'common/components/PopSide/PopSide';
// import { PopSideData_MOCK } from 'common/mocks/actionItemsPopSide_mock';

// import './ActionItems.scss';
import '../pages.scss';
import ActionItemsPopside from './popside/ActionItemsPopside';
import { useHistory, useLocation } from 'react-router-dom';
import { AddCommentPopup } from 'common/components/AddCommentPopup/AddCommentPopup';
import { setCommentsTabCount } from 'features/popSideSlice';
import CnappAlertPopside from './popside/CnappAlertPopside/CnappAlertPopside';
export interface IActionItems {
  rows?: any;
  path?: string;
  // showColumnPicker: boolean;
  isMoreButton: boolean;
  isCSVDownloadable: boolean;
  isPDFDownloadable?: boolean;
  count: number;
  columns: Array<any>;
  selectTab: string;
  rootPath: string;
  identifier: string;
}
export function ActionItemsRouting(props: any) {
  const [acknowledgeReason, setAcknowledgeReason] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeTabPopside, setActiveTabPopside] = useState('details');
  const [dataRow, setDataRow] = useState({});
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [actionItemsData, setActionItemsData] = useState<IActionItems>(initData());
  const [summaryData, setSummaryData] = useState<any>(null);
  const [popupData, setPopupData] = useState<any>([]);
  const [alertData, setAlertData] = useState<any>({});
  const [alertVisible, setAlertVisible] = useState<any>(false);
  const [abortControllerGrid, setAbortControllerGrid] = useState<AbortController>();
  const [abortControllerTopPanel, setAbortControllerTopPanel] = useState<AbortController>();
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const { globalFilter } = useSelector((state: IReduxState) => state.general);
  const [rowsIds, setRowsIds] = useState<any>([]);
  const { isOpen: isTopPanelOpne, setToggle: setTopPanelToggle } = useShowToggle(true);
  const { filters, rows, showAddCommentPopup, shouldOpenPopsideCommentsTab } = useSelector(
    (state: IReduxState) => state.grid
  );

  const location = useLocation();
  const history = useHistory();

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(removeAllRows());
    fetchGridData(props.selectTab);
    fetchTopPanelData(props.selectTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectTab]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('open_popside') && queryParams.get('open_popside') === 'true' && queryParams.has('ai_id')) {
      let url = `remediation/action-items/${props.selectTab}/${queryParams.get('ai_id')}/`;
      RestApi.getData(url).subscribe(
        (response: any) => {
          setDataRow(response);
          queryParams.delete('open_popside');
          queryParams.delete('is_open');
          queryParams.delete('ai_id');
          history.replace({
            search: queryParams.toString()
          });
          setVisible(true);
        },
        error => {}
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetQueryString());
    return () => {
      dispatch(removeAllRows());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!rows && !!rows.length) {
      setRowsIds(rows.map((row: any) => row.id));
    }
  }, [rows]);

  useEffect(() => {
    fetchTopPanelData(props.selectTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    if (!!shouldOpenPopsideCommentsTab.isOpen) {
      let url = `remediation/action-items/${props.selectTab}/${shouldOpenPopsideCommentsTab.id}/`;
      RestApi.getData(url).subscribe(
        (response: any) => {
          dispatch(setShouldOpenPopsideCommentsTab({ isOpen: false, id: '' }));
          dispatch(setCommentsTabCount(response.user_comments_count));
          setActiveTabPopside('comments');
          setDataRow(response);
          setVisible(true);
        },
        error => {}
      );
    }
  }, [shouldOpenPopsideCommentsTab, props.selectTab, dispatch]);

  function initData(): IActionItems {
    return {
      rows: [],
      // showColumnPicker: false,
      isMoreButton: true,
      isCSVDownloadable: false,
      count: 0,
      columns: [],
      selectTab: '',
      rootPath: '',
      identifier: ''
    };
  }

  const fetchTopPanelData = (tab: string) => {
    if (!Paths[TABSET[tab].tabPath]) {
      return;
    }
    if (abortControllerTopPanel) {
      abortControllerTopPanel.abort();
    }
    const abort_controller = new AbortController();
    setAbortControllerTopPanel(abort_controller);

    const url = !!globalFilter
      ? `${Paths[TABSET[tab].tabPath]}?global_filter=${globalFilter}`
      : Paths[TABSET[tab].tabPath];
    setIsSummaryLoading(true);
    RestApi.getData(url, true, abort_controller.signal).subscribe((response: any) => {
      setSummaryData({ ...response, inContext: tab });
      setIsSummaryLoading(false);
    });
  };

  const fetchGridData = (tab: string) => {
    setShowGrid(false);

    let _addFields: string[] = [];
    if (abortControllerGrid) {
      abortControllerGrid.abort();
    }
    const abort_controller = new AbortController();
    setAbortControllerGrid(abort_controller);

    RestApi.getData(Paths[TABSET[tab].columnPickerPath], true, abort_controller.signal).subscribe(
      (columns: IRootColumnPicker) => {
        columns.data.forEach((column: IColumnPicker) => {
          if (_addFields.includes(column.name)) {
            column.is_toggled = true;
          } else {
            const _filters = urlToStateConverter.getFilters();
            if (_filters[column.name] && _filters[column.name].value) {
              const _filter = column.choices.filter((choice: any) => choice.name === 1)[0];
              if (_filter) {
                _filter.is_toggled = true;
                if (_filter.add_fields) {
                  _addFields = _filter.add_fields;
                }
              }
            }
          }
        });
        const _columns = ColumnsManage.createColumns(
          columns.identifier_field,
          columns.data || [],
          {
            page: 'actionItems',
            tab: props.selectTab
          },
          {
            cb: onRowDoubleClick
          }
        );

        let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
        dispatch(setFields([...fields]));
        dispatch(addFields(_addFields));
        dispatch(setInitialPath(Paths[TABSET[tab].gridPath]));
        dispatch(setColumnPicker(columns.data));
        dispatch(setShowColumnPicker(columns.is_displayable));
        dispatch(setAdditionalFields(['last_rescan_by', 'last_rescan_time']));
        setActionItemsData({
          rows: [],
          // showColumnPicker: columns.is_displayable,
          isMoreButton: columns.is_more_button,
          isCSVDownloadable: columns.is_csv_downloadable,
          isPDFDownloadable: columns.is_pdf_downloadable,
          count: 0,
          identifier: columns.identifier_field,
          columns: _columns,
          selectTab: tab,
          rootPath: Paths[TABSET[tab].gridPath]
        });
        setShowGrid(true);
      }
    );
  };

  function onRowDoubleClick(params: GridRowParams) {
    if (props.selectTab === 'alerts') {
      RestApi.getData(`alerts/${params.id}`).subscribe(
        (response: any) => {
          setAlertData(response);
          setAlertVisible(true);
        },
        error => {}
      );
    } else {
      let url = `remediation/action-items/${props.selectTab}/${params.row[actionItemsData.identifier || 'id']}/`;
      RestApi.getData(url).subscribe(
        (response: any) => {
          setActiveTabPopside('details');
          dispatch(setCommentsTabCount(response.user_comments_count));
          setDataRow(response);
          setVisible(true);
        },
        error => {}
      );
    }
  }

  function onSelectionChange(params: ISelectionRowsChange) {
    if (params.moreData.label === 'Rescan') {
      let url = `remediation/action-items/rescan/`;
      let data = {
        ids: params.ids
      };

      RestApi.setData(url, data, 'POST').subscribe(
        (response: any) => {
          const indexes: any[] = [];
          params.ids.forEach(id => {
            let index = params.rows.findIndex((row: any) => row[actionItemsData.identifier] === id);
            indexes.push({ index: index });
          });
          dispatch(updateRescan({ indexes: indexes, status: SCAN_STATUS.IN_PROGRESS }));
          params.apiGrid.current.setSelectionModel([]);
        },
        error => {
          params.apiGrid.current.setSelectionModel([]);
          tosterService.onShowToster(error, 'danger', '');
        }
      );
    }

    if (params.moreData.label === 'Acknowledge') {
      if (params.ids.length !== 0) {
        setPopupData(params);
        setAcknowledgeReason(true);
      }
    }

    if (params.moreData.label === 'Unacknowledge') {
      if (params.ids.length === 0) {
        return;
      }

      let url = `remediation/action-items/acknowledge/`;
      let data = {
        ids: params.ids,
        is_acknowledged: false
      };

      RestApi.setData(url, data, 'POST').subscribe(
        (response: any) => {
          if (filters.is_acknowledged && filters.is_acknowledged.value === 1) {
            let rows = [...params.rows];
            const indexes: number[] = [];
            params.ids.forEach((id: GridRowId) => {
              let index = rows.findIndex((row: GridRowData) => row[actionItemsData.identifier] === id);
              indexes.push(index);
            });
            dispatch(removeRowsByIndex(indexes));
          } else {
            dispatch(updateRows({ rows: response, identifier: actionItemsData.identifier }));
          }
          tosterService.onShowToster('Unacknowledge Completed', 'success', '');
          params.apiGrid.current.setSelectionModel([]);
        },
        error => {
          tosterService.onShowToster('Error', 'danger', '');
          params.apiGrid.current.setSelectionModel([]);
        }
      );
    }
  }

  const getElementSelect = () => {
    if (props.selectTab === 'open') {
      return {
        header: 'Actions',
        options: OPTIONS,
        apiPath: 'remediation/action-items/'
      };
    }
    return undefined;
  };

  const showSummary =
    !!summaryData &&
    ((props.selectTab === 'open' && summaryData.inContext === 'open') ||
      (props.selectTab === 'closed' && summaryData.inContext === 'closed'));

  const handleOnHide = (isHide: boolean) => {
    setTimeout(() => {
      const selectedRow = document.querySelector('.action-item-popside-row-selected');
      if (!!selectedRow) {
        selectedRow.classList.remove('action-item-popside-row-selected');
      }
    }, 100);
    setVisible(isHide);
    setAlertVisible(isHide);
  };

  const handlePopupVisible = (visible: boolean) => {
    dispatch(setShowAddCommentPopup(visible));
  };

  return (
    <>
      {!!TABSET[props.selectTab] &&
        TABSET[props.selectTab].showSummary &&
        (!isSummaryLoading && showSummary ? (
          <TopPanel
            page={TOP_PANEL_PAGES.ACTION_ITEMS}
            tab={props.selectTab}
            data={summaryData}
            isLoading={isSummaryLoading}
            isOpen={isTopPanelOpne}
            setToggle={setTopPanelToggle}
          />
        ) : (
          <div>
            <MUIGrid container direction="column">
              <MUIGrid item className="top-panel-loading-state">
                <TopPanelLoadingState page={TOP_PANEL_PAGES.ACTION_ITEMS} isOpen={isTopPanelOpne} />
              </MUIGrid>
              <MUIGrid item sx={{ height: 10 }}></MUIGrid>
            </MUIGrid>
          </div>
        ))}
      {showGrid ? (
        <>
          <div style={{ height: '100%', zIndex: 1 }}>
            <Grid
              elementSelect={getElementSelect()}
              onSelectionRowsChange={onSelectionChange}
              onRowDoubleClick={onRowDoubleClick}
              columns={actionItemsData.columns}
              rows={actionItemsData.rows}
              isMoreButton={actionItemsData.isMoreButton}
              isCSVDownloadable={actionItemsData.isCSVDownloadable}
              isActionItemsPDFDownloadable={actionItemsData.isPDFDownloadable}
              count={actionItemsData.count}
              path={actionItemsData.path}
              savedViews={TABSET[props.selectTab].savedViews}
              // columnPicker={actionItemsData.columnPicker}
              // showColumnPicker={actionItemsData.showColumnPicker}
              identifier={actionItemsData.identifier}
              pageTab={TABSET[props.selectTab].gridPath}
              page={props.page}
              isClusterView={props.selectTab === 'open'}
            />
          </div>

          <PopUp setVisible={setAcknowledgeReason} visible={acknowledgeReason}>
            <AcknowledgeReason
              {...popupData}
              setVisible={setAcknowledgeReason}
              visible={acknowledgeReason}
              identifier={actionItemsData.identifier}
            />
          </PopUp>

          {visible && (
            <div className="action-items-popside">
              <PopSide visible={visible} onHide={handleOnHide}>
                {/* <ActionItemsInfo data={dataRow} setVisible={setVisible} /> */}
                <ActionItemsPopside
                  data={dataRow}
                  onHide={handleOnHide}
                  showActions={true}
                  rowsIds={rowsIds}
                  activeTab={activeTabPopside}
                />
              </PopSide>
            </div>
          )}
          <PopUp setVisible={handlePopupVisible} visible={showAddCommentPopup}>
            <AddCommentPopup setVisible={handlePopupVisible} />
          </PopUp>
          {alertVisible && (
            <div className="cnapp-alert-popside">
              <PopSide visible={alertVisible} onHide={handleOnHide}>
                {/* <ActionItemsInfo data={dataRow} setVisible={setVisible} /> */}
                <CnappAlertPopside data={alertData} onHide={handleOnHide} rowsIds={rowsIds} />
              </PopSide>
            </div>
          )}
          {/* <PopUp setVisible={setVisible} visible={visible}>
            <ActionItemsInfo data={dataRow} setVisible={setVisible} />
          </PopUp> */}
        </>
      ) : (
        <div className="grid-loading">Loading...</div>
      )}
    </>
  );
}
