import React, { useState, useEffect } from 'react';
import { CustomSwitch } from 'common/components/CustomSwitch/CustomSwitch';
import { Text } from '@cyberpion/cyberpion-ui';
import { ThreatsCenterContextType, useThreatsCenter } from '../ThreatsCenterContext';
import { useDispatch } from 'react-redux';
import { showSuccess, showError } from 'features/AlertSlice';
import { RestApi } from 'common/services/rest-api.service';
import classNames from 'classnames';
import SubscribeDropdown from './SubscribeDropdown/SubscribeDropdown';

import './ThreatCenterTopBar.scss';

const iconSubscribe = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.125 6H3.375L4.5 1.5L7.5 10.5L8.625 6H10.875"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const options = [
  {
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="subscribe-icon">{iconSubscribe}</div>
        <div style={{ marginLeft: '6px' }}>
          Subscribe to <b>All New Posts</b>
        </div>
      </div>
    ),
    name: 'all'
  },
  {
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="subscribe-icon">{iconSubscribe}</div>
        <div style={{ marginLeft: '6px' }}>
          Subscribe to only with <b>Confirmed Findings</b>
        </div>
      </div>
    ),
    name: 'confirmed'
  },
  { label: <div style={{ color: '#FA5958' }}>Unsubscribed</div>, name: 'unsubscribed' }
];

const ThreatCenterTopBar = () => {
  const [selectedOption, setSelectedOption] = useState({ label: 'Select Subsctiption', name: 'select' });
  const [isLoadingSubscribed, setIsLoadingSubscribed] = useState<boolean>(true);
  const {
    search,
    setSearch,
    showOnlyFindings,
    setShowOnlyFindings,
    setTimelineSelected,
    isLoadingAggregatedFindings
  } = useThreatsCenter() as ThreatsCenterContextType;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    RestApi.getData('threat-center/subscription/').subscribe(
      (response: any) => {
        const selected: any = !!response.type
          ? options.find(option => option.name === response.type)
          : { label: 'Select Subsctiption', name: 'select' };

        setSelectedOption(selected);
        setIsLoadingSubscribed(false);
      },
      error => {
        setIsLoadingSubscribed(false);
      }
    );
  }, []);

  const handleSwitch = (e: React.ChangeEvent, isChecked: boolean) => {
    setTimelineSelected(null);
    setShowOnlyFindings(isChecked);
  };

  const handleOnSearch = (e: any) => {
    setTimelineSelected(null);
    setSearch(e.target.value);
  };

  const handleSubscribe = (option: any) => {
    setIsLoadingSubscribed(true);
    RestApi.setData('threat-center/subscription/', { type: option.name }, 'PATCH').subscribe(
      (response: any) => {
        setSelectedOption(option);
        dispatch(showSuccess('Subscription updated'));
        setIsLoadingSubscribed(false);
      },
      error => {
        dispatch(showError('Something went wrong'));
        setIsLoadingSubscribed(false);
      }
    );
  };

  return (
    <div className="ThreatCenterTopBar">
      <div className="Search">
        <img alt="Search" src="/assets/images/search.svg" />
        <input id="input-search" type="text" maxLength={30} value={search} onChange={handleOnSearch} />
      </div>
      <div className={classNames('subscribe-dropdown', { loading: !!isLoadingSubscribed })}>
        <SubscribeDropdown
          selectedOption={selectedOption}
          options={options}
          isLoadingSubscribed={!!isLoadingSubscribed}
          subscribedOnClick={handleSubscribe}
        />
      </div>

      <div className="top-bar-switch">
        <Text textColor="#707070" style={{ marginRight: 12 }}>
          Hide threats without findings
        </Text>
        <div className={classNames('show-only-findings', { disabled: !!isLoadingAggregatedFindings })}>
          <CustomSwitch checked={showOnlyFindings} onChange={handleSwitch} />
        </div>
      </div>
    </div>
  );
};

export default ThreatCenterTopBar;
