import InfiniteScroll from 'react-infinite-scroll-component';
import './ClusterGrid.scss';
import { useEffect, useRef, useState } from 'react';
import { ClusterCollapsePanel, ClusterSortPanel } from '@cyberpion/cyberpion-ui';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from '../appLoading/appLoading';
import classNames from 'classnames';
import { ClusterGridTable } from './ClusterGridTable/ClusterGridTable';
import { useDispatch, useSelector } from 'react-redux';
import { queryBuilder } from '../grid/query-builder.service';
import { setClusterCount, setClusterSort } from 'features/GridSlice';
import { selectView, setIsEdited } from 'features/ViewsSlice';
import { ActionItemsIcon } from '../manageNotData/components/actionItemsIcon/ActionItemsIcon';

const sortData = [
  {
    name: 'cluster',
    title: 'Cluster',
    options: [
      {
        name: 'cluster_name',
        title: 'Title'
      },
      {
        name: 'cluster_count',
        title: 'Count'
      }
    ]
  },
  {
    name: 'action_items',
    title: 'Action Items',
    options: [
      { name: 'max_urgency', title: 'Highest Urgency' },
      { name: 'urgency_count', title: 'Urgency Count' }
    ]
  },
  {
    name: 'tags',
    title: 'Tags'
  },
  {
    name: 'groups',
    title: 'Groups'
  }
];

const CLUSTERS_PER_PAGE = 20;

export function ClusterGrid(props: any) {
  const [data, setData] = useState<any[]>([]);
  const [isNextPage, setIsNextPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { filters, initialPath, search, fields, clusterSort } = useSelector((state: any) => state.grid);
  const { globalFilter } = useSelector((state: any) => state.general);
  const [selections, setSelections] = useState<any>({});
  const page = useRef(0);
  const dispatch = useDispatch<any>();
  const fetch = () => {
    queryBuilder.setBaseURL(initialPath);
    queryBuilder.buildQuery({ filters: filters, search: search, order: '', fields: fields }, globalFilter); // <- HERE!!!
    let query = queryBuilder.getQuery();
    RestApi.getData(
      `remediation/action-items/open/clusters/?${query.split('?')[1]}&clusterBy=${props.type}${
        clusterSort ? `&ordering=${clusterSort}` : ''
      }&limit=${CLUSTERS_PER_PAGE}&offset=${page.current * CLUSTERS_PER_PAGE}`
    ).subscribe((response: any) => {
      if (response.results) {
        setIsNextPage(!!response.next);
        setData((prev: any) => {
          return [...prev, ...response.results];
        });
        dispatch(setClusterCount(response.count));
        setLoading(false);
      } else {
        setData([]);
        setIsNextPage(false);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setData([]);
    page.current = 0;
    setLoading(true);
    fetch();
    setSelections({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, props.type, fields, search, clusterSort]);

  const onSort = (_sortBy: string) => {
    let prefix = '-';
    if (clusterSort.includes(_sortBy)) {
      prefix = clusterSort.startsWith('-') ? '' : '-';
    }
    dispatch(setClusterSort(`${prefix}${_sortBy}`));
    dispatch(setIsEdited(true));
  };

  useEffect(() => {
    return () => {
      dispatch(selectView(null));
      setSelections({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.onSelection(Object.values(selections).flat());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections]);

  return (
    <div className="ClusterGrid">
      <div className={classNames({ disabled: !data.length })}>
        <ClusterSortPanel
          sortedBy={clusterSort.replace('-', '')}
          onUnsort={() => {
            dispatch(setClusterSort(''));
          }}
          data={sortData}
          sortCB={onSort}
        />
      </div>
      {loading ? (
        <AppLoading />
      ) : (
        <div id="ClusterGrid">
          <InfiniteScroll
            dataLength={data.length}
            scrollableTarget={'ClusterGrid'}
            next={() => {
              page.current = page.current + 1;
              fetch();
            }}
            hasMore={isNextPage}
            loader={<AppLoading className={classNames(`cluster-loader`, { isNextPage: data.length })} />}
            className="clusters-wrapper"
          >
            {data.map((d: any) => {
              return (
                <ClusterCollapsePanel
                  data={d}
                  content={
                    <ClusterGridTable
                      onSelection={(model: string[]) => {
                        const selectionsCopy = { ...selections };
                        selectionsCopy[d.cluster_name] = model;
                        setSelections(selectionsCopy);
                      }}
                      data={d}
                      columns={props.columns}
                      type={props.type}
                    />
                  }
                  collapseCB={() => {}}
                />
              );
            })}
            {!data.length && (
              <div className="cluster-empty-state">
                <ActionItemsIcon />
              </div>
            )}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}
